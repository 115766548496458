.featuredItemsOuterContainer {
  margin: 8vh auto 2vh;
  width: 90%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.featuredItemsMainTitle {
  padding: 0 3% 1.5vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 1.2;
  letter-spacing: -0.6px;
  word-spacing: 2px;
  z-index: 9;
}

.featuredItemsInnerContainer {
  width: 100%;
  margin: 0 auto;
  padding: 1vh 5% 0;
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 5px;
  border-top: 1px dashed #242424;
}
