.LoadingBoxContainer {
  width: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.LoadingBox {
  width: 5px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgb(29, 69, 105);
  animation-name: loading, colors;
  animation-duration: 0.4s, 10s;
  animation-timing-function: linear, ease;
  animation-iteration-count: infinite, infinite;
  animation-direction: alternate, normal;
}

@keyframes loading {
  0% {
    width: 1px;
    height: 1px;
  }
  65% {
    width: 2px;
    height: 10px;
  }
  100% {
    width: 5px;
    height: 35px;
  }
}
@keyframes colors {
  0% {
    background: hsl(180, 80%, 40%);
  }
  25% {
    background: hsl(270, 80%, 40%);
  }
  50% {
    background: hsl(0, 80%, 50%);
  }
  75% {
    background: hsl(90, 80%, 45%);
  }
  100% {
    background: hsl(180, 80%, 40%);
  }
}

.LoadingBox1 {
  animation-delay: 0s, 0s;
}
.LoadingBox2 {
  animation-delay: 0.08s, 0s;
}
.LoadingBox3 {
  animation-delay: 0.16s, 0s;
}
.LoadingBox4 {
  animation-delay: 0.24s, 0s;
}
.LoadingBox5 {
  animation-delay: 0.32s, 0s;
}
