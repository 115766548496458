.footerContainer {
    width: 100%;
    padding: 3vh 10% 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 0;
    justify-self: flex-end;
    align-self: flex-end;
    border-top: var(--dashed-border);
    background: var(--main-color);
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
}

.footerLogo {
    margin-bottom: -2px;
    font-family: 'Abril Fatface', cursive;
    font-size: 2.5em;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    align-self: flex-end;
}

.footerAbout {
    margin-top: 1vh;
    color: #737373;
    font-weight: 300;
    line-height: 1.2;
    display: flex;
    justify-content: flex-start;
    text-align: justify;
    flex-direction: column;
    gap: 1.5vh;
}

.footerLinksContainer {
    width: 100%;
    padding-bottom: 1.5vh;
    margin-bottom: 1.5vh;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr auto;
    border-bottom: 1px dashed #737373;
    column-gap: 10%;
    row-gap: 0.5vh;
}

.footerLinksTitle {
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    text-align: right;
    line-height: 1;
    cursor: pointer;
    justify-self: flex-end;
    align-self: flex-end;
}
.footerLinksList ul {
    display: flex;
    flex-direction: column;
    text-align: right;
    color: #8d8d8d;
    list-style: none;
}
.footerLinksList a {
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.1s ease-out;
}
.footerLinksList a:hover {
    color: #ececec;
}

.footerCopyright {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1vh;
    color: #8a8a8a;
}

.footerCopyrightText {
    font-weight: 300;
    gap: 5px;
}
.footerCopyrightName {
    color: #8a8a8a;
    font-weight: 500;
    text-shadow: 0 0 1px #b8b8b8;
    cursor: pointer;
    animation: secret 7s ease-in-out 5s infinite;
}
.footerCopyrightName:hover {
    animation: heartbeat 1s linear 1.2s infinite;
    cursor: help;
}

@keyframes heartbeat {
    0% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    40% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    52.5% {
        color: #c6c6c6;
        text-shadow: 0 0 5px #c6c6c6;
    }
    65% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    77.5% {
        color: #c6c6c6;
        text-shadow: 0 0 5px #c6c6c6;
    }
    90% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
}

@keyframes secret {
    0% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    2% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    5% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    7% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    10% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    12% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    15% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    17% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    21% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    36% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    38% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    39% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    54% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    56% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    57% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    72% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    74% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    76% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    78% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    81% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    83% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    86% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    88% {
        color: #e6e6e6;
        text-shadow: 0 0 5px #ffffff;
    }
    91% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
    100% {
        color: #8a8a8a;
        text-shadow: 0 0 1px #b8b8b8;
    }
}

.footerCopyrightSocial {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.footerCopyrightSocialLink {
    width: 25px;
    height: 25px;
    padding: 4px;
    position: relative;
    top: 0px;
    font-size: 1.5em;
    color: var(--main-color);
    background: #8a8a8a;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(51, 51, 51, 0);
    transition: 0.1s ease-out, transform 0.1s ease-out, color 0.2s ease-in-out 0.05s;
}
.footerCopyrightSocialLink:hover {
    top: -0px;
    transform: translateY(-2px);
    color: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 1);
    /* border-radius: 50%; */
}

.footerCopyrightSocialFacebook:hover {
    background: #4267b2;
}
.footerCopyrightSocialTwitter {
    padding: 5px;
}
.footerCopyrightSocialTwitter:hover {
    background: #1da1f2;
}
.footerCopyrightSocialLinkedin:hover {
    background: #0077b5;
}
.footerCopyrightSocialInstagram:hover {
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
}

.footerCopyrightSocialGithub {
    padding: 2px;
    color: #414141;
}
.footerCopyrightSocialGithub:hover {
    color: black;
    background: white;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 1);
}

@media (max-width: 625px) {
    .footerCopyright {
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1vh;
        color: #8a8a8a;
    }
}
