.profileScreenContainer {
  width: 100%;
  padding: 10vh 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  color: var(--main-color);
  background: white;
}

.profileContainer {
  width: 100%;
  max-width: 650px;
  padding: 20px 25px 30px;
  margin-bottom: 20px;
  border: 1px dashed rgb(211, 211, 211);
  border-radius: 4px;
  position: relative;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.profileTitle {
  font-size: 2.5em;
  font-weight: 900;
  margin-bottom: 10px;
}

.profileWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.profileLabel {
  font-weight: 600;
  color: var(--main-color);
}

.profileInput {
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px dashed var(--cart-border-color);
  border-radius: 5px;
  background: hsl(210, 100%, 98%);
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 12px;
}

.profileButton {
  width: 100%;
  padding: 15px 25px;
  margin-top: 5px;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  user-select: none;
  background: linear-gradient(var(--button-click-color), var(--button-color));
  cursor: pointer;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}
.profileButton:hover {
  filter: brightness(1.3);
}
.profileButton:hover:active {
  box-shadow: 0px 0px 5px var(--button-color);
}
