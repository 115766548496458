li {
  list-style: none;
}

.orderHistoryScreenContainer {
  width: 100%;
  padding: 5vh 5% 5vh;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background: white;
}

.orderHistoryScreenTitle {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;
  justify-self: center;
}

.orderHistoryScreenInnerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background: white;
  gap: 20px;
}

.orderHistoryScreenSubTitle {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  align-self: flex-start;
}

.orderHistoryScreenItemsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.orderHistoryScreenItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cartScreenItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px dashed #dddddd;
}
.cartScreenItem:last-child {
  border-bottom: none;
}

.cartScreenItemImage {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.cartScreenItemDetails {
  width: 100%;
  margin-left: 10px;
  display: grid;
  grid-template-columns: 9fr 8fr 3fr;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-weight: 300;
  gap: 5px;
}

.cartScreenItemDetailWrapper {
  font-size: 12px;
  align-self: flex-start;
}

.cartScreenItemDetailsName {
  font-size: calc(12px + 0.1vmax);
  font-weight: 500;
  line-height: 1.1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cartScreenItemDetailsArtist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* font-size: calc(9px + 0.1vmax); */
}
.cartScreenItemDetailsDevice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cartScreenItemDetailsPrice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.orderContainer {
  width: 100%;
  margin-bottom: 2vh;
  padding-bottom: 1vh;
  list-style: none;
  border: 1px dashed #d4d4d4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.orderContainerNone {
  width: 100%;
  margin-bottom: 2vh;
  padding: 5vh 0;
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  border: 1px dashed #d4d4d4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.orderSummaryContainer {
  width: 100%;
  margin-bottom: 1vh;
  padding: 1vh 5%;
  background: hsl(210, 50%, 92%);
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
  border-bottom: 1px dashed #dddddd;
}

.orderSummaryInnerContainer {
  justify-self: flex-start;
  text-align: center;
}
.orderSummaryTax {
  text-align: center;
  justify-self: flex-start;
}
.orderSummaryTotal {
  text-align: center;
  justify-self: flex-end;
}

.orderItemsContainer {
  width: 100%;
  padding: 0.5vh 0;
}

.orderItemContainer {
  width: 100%;
  padding: 0 5% 0 5px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
