.quantityBoxContainerStyle {
  width: 100%;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 7fr;
  align-items: center;
}
.quantityBoxInputContainer {
  height: 27px;
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.quantityBoxInput {
  width: 35px;
  height: 100%;
  border: none;

  text-align: center;
  background: hsl(0, 0%, 95%);
  box-shadow: inset 0px 0px 2px #838383;
}

.quantityBoxInputButton {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}

.quantityBoxInputButtonActive {
  border: none;
  background: linear-gradient(var(--button-click-color), var(--button-color));
  color: white;
}
.quantityBoxInputButtonActive:hover {
  filter: brightness(1.3);
}
.quantityBoxInputButtonActive:hover:active {
  background: var(--button-click-color);
  box-shadow: inset 1px 1px 1px #1e2225;
  text-shadow: 2px 2px 2px #000000;
}

.quantityBoxInputButtonDisabled {
  border: none;
  background: var(--button-disabled-color);
  color: white;
  box-shadow: inset 0px 0px 2px rgb(220, 220, 220);
  cursor: default;
}

.quantityBoxInputButtonMinus {
  border-radius: 2px 0 0 2px;
}
.quantityBoxInputButtonPlus {
  border-radius: 0 2px 2px 0;
}

.removeArrows::-webkit-inner-spin-button {
  appearance: none;
}
.removeArrows::-webkit-outer-spin-button {
  appearance: none;
}

.removeArrows {
  appearance: textfield;
}
