.featuredItemStars {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(11px + 15%);
  color: #ffc000;
  transition: 0.2s ease;
}

.featuredItemNumReviews {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: calc(8px + 15%);
  font-weight: 300;
  color: #242424;
  transition: 0.2s ease;
}
