.cartScreenContainer {
    width: 100%;
    padding: 5vh 5% 5vh;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    background: white;
}

.cartScreenTitle {
    font-size: 2.25rem;
    font-weight: 900;
    margin-bottom: 10px;
    text-align: center;
    justify-self: center;
}

.cartScreenItemsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 2vh;
}

.cartScreenItems {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 1px dashed #d4d4d4;
    border-radius: 5px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.cartScreenItem {
    width: 100%;
    height: 100%;
    padding-right: 2%;
    padding-bottom: 0.5vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px dashed #dddddd;
}

.cartScreenItem:last-child {
    border-bottom: none;
}

.cartScreenItemNone {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-size: 1.1em;
    font-weight: 400;
    color: #242424;
}

.cartScreenItemImage {
    width: 12%;
    min-width: 100px;
    margin-right: 5%;
    cursor: pointer;
    transition: 0.1s ease-out;
}
.cartScreenItemImage:hover {
    transform: scale(1.1);
}

.cartScreenItemDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 300;
}

.cartScreenItemDetail {
    align-self: flex-start;
    text-align: right;
}

.cartScreenItemDetailsName {
    font-size: 1.3em;
    font-weight: 700;
    line-height: 1.1;
    align-self: flex-end;
    cursor: pointer;
}
.cartScreenItemDetailsArtist {
    font-size: 1em;
    align-self: flex-start;
    cursor: pointer;
}

.cartScreenItemDetailsDevice {
    font-size: 1em;
    font-weight: 500;
}

.cartScreenItemDetailsQtyContainer {
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.cartScreenItemDetailsQty {
    margin-right: 5px;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
}
.cartScreenItemDetailsQtyInput {
    width: 30px;
    padding: 5px;
    text-align: center;
    /* -moz-appearance: textfield; */
}
.cartScreenItemDetailsQty span {
    font-weight: 700;
}
.cartScreenItemDetailsQtyInput::-webkit-inner-spin-button {
    appearance: none;
}
.cartScreenItemDelete {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    font-weight: 400;
    color: hsl(205, 80%, 45%);
    border: none;
    background: transparent;
    cursor: pointer;
}

.cartScreenItemDetailsPrice {
    margin-top: 2px;
    align-self: flex-start;
    font-size: 1.3em;
    line-height: 1;
    font-weight: 400;
}

.cartScreenTotal {
    width: 28%;
    height: 100%;
    padding: 2vh 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d4d4d4;
    border-radius: 5px;
    text-align: center;
    gap: 1vh;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.cartScreenTotal p {
    font-weight: 400;
}

.cartScreenCheckoutBtn {
    width: 100%;
    padding: 15px 25px;
    font-family: 'Open Sans', 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(230, 230, 230);
    border: none;
    border-radius: 5px;
    user-select: none;
    transition: 0.1s ease;
}

.cartScreenCheckoutBtnActive {
    background: linear-gradient(var(--button-click-color), var(--button-color));
    cursor: pointer;
}
.cartScreenCheckoutBtnActive:hover {
    filter: brightness(1.3);
}
.cartScreenCheckoutBtnActive:hover:active {
    box-shadow: 0px 0px 5px var(--button-color);
}

.cartScreenCheckoutBtnDisabled {
    background: var(--button-disabled-color);
    box-shadow: inset 0px 0px 2px rgb(230, 230, 230);
    color: white;
    border: none;
    cursor: default;
}
.cartScreenCheckoutBtn p {
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(207, 207, 207);
}

@media (max-width: 820px) {
    .cartScreenItems {
        width: 100%;
    }

    .cartScreenItem {
        padding: 1vh 2% 1vh 0;
    }

    .cartScreenTotal {
        width: 100%;
    }
}
