.paymentScreenContainer {
  width: 100%;
  padding: 5vh 15% 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 13px;
  color: var(--main-color);
  background: white;
}

.paymentScreenFormContainer {
  width: 100%;
  padding: 20px 25px 30px;
  display: flex;
  flex-direction: column;
  border: 1px dashed rgb(211, 211, 211);
  border-radius: 4px;
  position: relative;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.paymentScreenTitle {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;
  justify-self: center;
}

.paymentScreenWrapper {
  margin-left: 2%;
  margin-bottom: 2vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.paymentScreenInput {
  color: var(--highlight-color);
  border-color: var(--highlight-color);
  cursor: pointer;
}
.paymentScreenInput:checked {
  color: var(--main-color);
}

.paymentScreenLabel {
  margin-left: 1%;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--highlight-color);
  cursor: pointer;
}
.paymentScreenInput:checked ~ .paymentScreenLabel {
  color: var(--main-color);
}

.paymentScreenButton {
  width: 100%;
  padding: 15px 25px;
  margin-top: 5px;
  font-family: 'Poppins', 'Open Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  user-select: none;
  background: linear-gradient(var(--button-click-color), var(--button-color));
  cursor: pointer;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}
.paymentScreenButton:hover {
  filter: brightness(1.3);
}
.paymentScreenButton:hover:active {
  box-shadow: 0px 0px 5px var(--button-color);
}
