a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.navbarContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: center;
    position: sticky;
    top: 0;
    font-family: 'Poppins', sans-serif;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.navbarTop {
    max-height: var(--navbar-top-height);
    padding: 2vh 8%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6));
    color: var(--main-color);
    line-height: 1;
    backdrop-filter: blur(30px) brightness(1.2);
    -webkit-backdrop-filter: blur(30px) brightness(1.2);
    z-index: 9999999;
}

.navbarLogo {
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    bottom: 2px;
    z-index: 999999;
}
.navbarLogo h1 {
    font-family: 'Abril Fatface', cursive;
    font-size: 48px;
    font-weight: 400;
    z-index: 999999;
}

.navbarInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    justify-self: flex-end;
    grid-template-rows: repeat(3, 1fr);
    font-size: 12px;
    letter-spacing: calc(0.03 * 1vmax);
    white-space: nowrap;
    gap: 3px;
}

.navbarInfoLine {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 300;
}
.separators {
    margin: 0 10px;
}

.customerServiceContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
}

.searchBoxContainerLink {
    font-weight: 600;
    transition: 0.2s ease, color 0.1s ease;
    display: inline-block;
}

.searchBoxContainerLink::after {
    content: '';
    width: 0;
    height: 1px;
    display: block;
    position: relative;
    bottom: 1px;
    background: var(--main-color);
    transition: 0.2s ease-in-out;
}
.searchBoxContainerLink:hover::after {
    width: 100%;
    background: var(--main-color);
}

.searchBoxContainerLink span {
    display: flex;
    align-items: center;
    gap: 3px;
}

.searchBoxContainerTel {
    font-weight: 600;
    transition: 0.2s ease, color 0.1s ease;
    display: inline-block;
}

.profileLinkContainer {
    position: relative;
    top: 0;
}

.profileLinkTitle {
    min-width: 120px;
    padding: 0.5vh 0;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    line-height: 2;
    color: white;
    background: var(--main-color);
    border: var(--dashed-border);
    border-radius: 5px;
    cursor: pointer;
    transition: 0s;
}
.profileLinkDropdownContainer:hover ~ .profileLinkTitle {
    background: var(--main-color);
    border-top: var(--dashed-border);
    border-left: var(--dashed-border);
    border-right: var(--dashed-border);
    border-bottom: 1px dashed transparent;
    border-radius: 5px 5px 0 0;
    color: white;
    transition: 0.1s ease-in-out;
}

.profileLinkDropdownContainer {
    opacity: 0;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-bottom: var(--dashed-border);
    border-left: var(--dashed-border);
    border-right: var(--dashed-border);
    background: var(--main-color);
    color: white;
    transition: 0.1s ease-in-out;
    z-index: 9999999;
}
.profileLinkTitle:hover + .profileLinkDropdownContainer,
.profileLinkDropdownContainer:hover,
.profileLinkDropdownContainer:focus-within,
.profileLink:focus .profileLinkDropdownContainer {
    opacity: 1;
    height: auto;
    font-size: 1em;
}

.profileLink {
    width: 100%;
    height: 0;
    font-size: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileLinkTitle:hover + .profileLinkDropdownContainer > .profileLink,
.profileLinkDropdownContainer:hover .profileLink {
    height: auto;
    font-size: 1em;
    padding: 1vh 5px;
}

.profileLink:hover {
    animation: pulse 0.6s ease infinite alternate;
}

.navbarIcons {
    font-size: 1.5em;
    line-height: 1.2;
    color: var(--main-color);
    position: relative;
}
.navbarIconsShopping {
    font-size: 1.5em;
    line-height: 1.2;
    color: var(--main-color);
    position: relative;
}

.shoppingCartQuantityWrapper {
    display: flex;
    position: relative;
}

.shoppingCartQuantity {
    width: 16px;
    aspect-ratio: 1;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85em;
    font-weight: 400;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    bottom: -50%;
    left: -40%;
    background: var(--highlight-color);
    z-index: 999;
}

.searchBoxContainer {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
}

.searchBoxContainer a {
    margin-left: 10px;
    text-decoration: none;
    color: var(--main-color);
    transition: 0.2 ease;
}

.searchBox {
    width: 200px;
    height: 100%;
    margin-left: 2%;
    padding: 1.2vh 5%;
    border: none;
    border-bottom: 1px solid rgb(220, 220, 220);
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: var(--main-color);
    background-color: rgb(245, 245, 245);
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}
.searchBox:focus {
    outline: none;
    border: 1px dashed currentColor;
}
.searchButton {
    width: 25px;
    height: 100%;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    border-bottom: 1px solid rgb(220, 220, 220);
    background-color: rgb(245, 245, 245);
    cursor: pointer;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.searchButtonIcon {
    font-size: 1.2em;
    font-weight: 400;
    color: var(--button-highlight-color);
    transform: rotate(0deg);
    transition: 0.2s ease;
}
.searchButton:hover .searchButtonIcon {
    color: var(--main-color);
}

.navbarMainMenu {
    max-height: var(--navbar-menu-height);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    border-top: var(--dashed-border);
    border-bottom: var(--dashed-border);
    overflow: hidden;
    z-index: 999;
}

.navbarMenuOptions {
    width: 100%;
    max-height: var(--navbar-menu-height);
    padding: 0 calc(5px + 15%);
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: calc(1px / var(--g-ratio));
    color: white;
    text-shadow: 1px 2px 4px black;
}

.navbarMenuLink {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarMenuOptions li {
    max-height: var(--navbar-menu-height);
    width: 80%;
    padding: 1vh 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    white-space: nowrap;
}
.navbarMenuOptions li:hover {
    animation: pulse 0.6s ease infinite alternate;
}

@keyframes pulse {
    0% {
        background: var(--main-color);
    }
    100% {
        background: #757575;
    }
}

.mobileNavContainer {
    width: 100%;
    display: none;
    justify-content: flex-end;
    align-items: center;
    column-gap: 30%;
    z-index: 99999;
}
.mobileNavIconShopping {
    font-size: calc(25px + 1vmin);
    z-index: 99999;
}

.mobileshoppingCartQuantity {
    width: calc(12px + 1vmin);
    aspect-ratio: 1;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85em;
    font-weight: 400;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    bottom: -10%;
    left: -10%;
    background: var(--highlight-color);
    z-index: 99999;
}

.mobileNavIconNavWrapper {
    width: calc(20px + 1vmin);
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 99999;
    transition: 0.5s ease-in-out;
}

.mobileNavIconNavWrapperClose {
    width: calc(20px + 1vmin);
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transform: rotate(0deg);
    position: relative;
    transition: 0.5s ease-in-out;
    z-index: 99999;
}

.mobileNavIconNavLine1,
.mobileNavIconNavLine2,
.mobileNavIconNavLine3,
.mobileNavIconNavLine4 {
    width: 100%;
    height: 2px;
    background: #242424;
    transition: 0.3s ease-in-out 0.4s, transform 0.5s ease-in-out;
}

.mobileNavIconNavLineClose1,
.mobileNavIconNavLineClose2,
.mobileNavIconNavLineClose3,
.mobileNavIconNavLineClose4 {
    width: 100%;
    height: 2px;
    background: #242424;
    transition: 0.3s ease-in-out, transform 0.5s ease-in-out 0.1s;
}

.mobileNavIconNavLine1 {
    position: absolute;
    top: 5%;
    transform: rotate3d(0, 0, 0, 0deg);
}
.mobileNavIconNavLine2 {
    position: absolute;
    top: 35%;
    transform: rotate3d(0, 0, 0, 0deg);
}
.mobileNavIconNavLine3 {
    position: absolute;
    top: 65%;
    transform: rotate3d(0, 0, 0, 0deg);
}
.mobileNavIconNavLine4 {
    position: absolute;
    top: 95%;
    transform: rotate3d(0, 0, 0, 0deg);
}
.mobileNavIconNavLineClose1 {
    position: absolute;
    top: 50%;
    transform: rotateZ(225deg);
}
.mobileNavIconNavLineClose2 {
    position: absolute;
    top: 50%;
    transform: rotateZ(135deg);
}
.mobileNavIconNavLineClose3 {
    position: absolute;
    top: 50%;
    transform: rotateZ(135deg);
}
.mobileNavIconNavLineClose4 {
    position: absolute;
    top: 50%;
    transform: rotateZ(225deg);
}

.mobileNavMenuWrapper {
    width: 100%;
    height: 100vh;
    padding: 20vh 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0.95));
    z-index: 999;
    transition: 0.5s ease;
}
.mobileNavMenuWrapperClose {
    width: 100%;
    height: 100vh;
    padding: 20vh 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    background: radial-gradient(hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0.95));
    z-index: 999;
    transition: 0.5s ease;
}

.mobileNavMenuLink {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #242424;
    text-shadow: 0 0 10px white;
}

@media (max-width: 625px) {
    .navbarInfoContainer {
        display: none;
    }

    .navbarMainMenu {
        display: none;
    }

    .mobileNavContainer {
        display: flex;
    }
}
