.shippingScreenContainer {
    width: 100%;
    padding: 5vh 15% 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 13px;
    color: var(--main-color);
    background: white;
}

.shippingScreenFormContainer {
    width: 100%;
    padding: 20px 25px 30px;
    display: flex;
    flex-direction: column;
    border: 1px dashed rgb(211, 211, 211);
    border-radius: 4px;
    position: relative;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.shippingScreenFormContainer span {
    color: hsl(0, 90%, 40%);
}
.shippingScreenTitle {
    font-size: 2.25rem;
    font-weight: 900;
    margin-bottom: 10px;
    text-align: center;
    justify-self: center;
}
.shippingScreenFullNameContainer,
.shippingScreenAddressContainer,
.shippingScreenCityContainer,
.shippingScreenProvinceContainer,
.shippingScreenPostalCodeContainer,
.shippingScreenCountryContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.shippingScreenFullNameLabel,
.shippingScreenAddressLabel,
.shippingScreenCityLabel,
.shippingScreenProvinceLabel,
.shippingScreenPostalCodeLabel,
.shippingScreenCountryLabel {
    font-weight: 600;
    color: var(--main-color);
}

.shippingScreenFullNameInput,
.shippingScreenAddressInput,
.shippingScreenCityInput,
.shippingScreenProvinceInput,
.shippingScreenPostalCodeInput,
.shippingScreenCountryInput {
    width: 100%;
    height: 35px;
    padding: 2px 10px;
    margin-bottom: 10px;
    border: 1px dashed var(--cart-border-color);
    border-radius: 5px;
    background: hsl(210, 100%, 98%);
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 12px;
}

.shippingScreenCityFormRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3%;
}

.shippingScreenButton {
    width: 100%;
    padding: 15px 25px;
    margin-top: 5px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 5px;
    user-select: none;
    background: linear-gradient(var(--button-click-color), var(--button-color));
    cursor: pointer;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}
.shippingScreenButton:hover {
    filter: brightness(1.3);
}
.shippingScreenButton:hover:active {
    box-shadow: 0px 0px 5px var(--button-color);
}

.shippingScreenlegend {
    font-size: 12px;
    color: hsl(0, 100%, 43%);
    align-self: flex-end;
    text-align: right;
}

@media (max-width: 820px) {
    .shippingScreenContainer {
        padding: 5vh 8% 10vh;
    }

    .shippingScreenCityFormRow {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 3%;
    }
}
