.featuredItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 1vh;
  box-sizing: content-box;
  border: 1px dashed transparent;
  transition: 1s ease;
}

.featuredItemImgWrapper {
  max-width: 200px;
  min-width: 150px;
  aspect-ratio: 1;
  overflow: hidden;
}

.featuredItemImg {
  width: 100%;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 300;
  transition: 0.2s ease;
  z-index: -1;
}

.featuredItem:hover .featuredItemImg {
  transform: scale(1.05);
}

.featuredItemText {
  width: 100%;
  padding: 0 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  z-index: 999;
}

.featuredItemTitle {
  width: 100%;
  height: 2rem;
  margin-bottom: 0.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: calc(10px + 13%);
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: -0.5px;
  word-spacing: 1px;
  line-height: 1.3;
}

.featuredItemArtist {
  height: 2rem;
  margin-bottom: 0.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: calc(9px + 13%);
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: -0.2px;
  line-height: 1.3;
  white-space: nowrap;
  color: rgb(145, 145, 145);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: 0.2s ease;
}
.featuredItemArtist:hover {
  font-weight: 500;
  color: var(--button-color);
}

.featuredItemRating {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: calc(15px + 13%);
  font-weight: 400;
  gap: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: 0.2s ease;
}

.featuredItemPrice {
  width: 100%;
  font-size: calc(14px + 13%);
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  letter-spacing: -0.7px;
}
