.App {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    /* min-width: 700px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow-x: hidden;
}

.AppBodyContainer {
    width: 100%;
    height: 100%;
    padding-bottom: 30vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.toastContainer {
    width: 100%;
    min-width: 250px;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toast {
    width: 80%;
    padding: 5px 75px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(to bottom right, hsl(200, 91%, 45%), hsl(210, 100%, 30%));
    border: 1px solid white;
    border-radius: 10px;
    opacity: 0.7;
}

.toastBody {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}
.toastProgress {
    height: 10px;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
