.orderDetailsScreenContainer {
    width: 100%;
    padding: 5vh 5% 5vh;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    background: white;
}

.orderDetailsScreenTitle {
    font-size: calc(16px + 1.5vw);
    font-weight: 900;
    margin-bottom: 10px;
    text-align: center;
    justify-self: center;
}

.orderDetailsScreenInnerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    background: white;
    gap: 20px;
}

.orderDetailsScreenSubTitle {
    margin-bottom: 1vh;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.1;
    align-self: flex-start;
}

.orderDetailsScreenInnerContainerLeft {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.orderDetailsScreenItemsContainer {
    width: 100%;
    display: flex;
    gap: 20px;
}

.orderDetailsScreenItems {
    width: 100%;
    padding: 2vh 0 1vh;
    border: 1px dashed #d4d4d4;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.orderDetailsScreenSubTitleItems {
    padding-left: 5%;
    padding-bottom: 0.5vh;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-weight: 800;
    line-height: 1.1;
    text-transform: uppercase;
    align-self: flex-start;
}

.orderDetailsScreenItemNone {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-size: 1.1em;
    font-weight: 400;
    color: #242424;
}

.orderDetailsScreenAddress {
    padding-left: 7%;
    padding-bottom: 1.5vh;
    align-self: flex-start;
}

.orderDetailsScreenUpdates {
    width: 100%;
    padding-left: 7%;
    padding-top: 1vh;
    border-top: 1px dashed #d4d4d4;
    font-weight: 400;
    align-self: flex-start;
}

.orderDetailsScreenDeliveredTrue,
.orderDetailsScreenPaidTrue {
    color: hsl(120, 90%, 30%);
}
.orderDetailsScreenPaidFalse {
    color: hsl(0, 90%, 45%);
}
.orderDetailsScreenDeliveredFalse {
    color: hsl(0, 0%, 70%);
}

.Link {
    width: 100%;
    height: 100%;
    padding-right: 2%;
    padding-bottom: 0.5vh;
    border-bottom: 1px dashed #dddddd;
}
.Link:last-child {
    border-bottom: none;
}
.orderDetailsScreenItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.orderDetailsScreenItemImageWrapper {
    min-width: 100px;
    width: 12%;
    margin-right: 5%;
    overflow: hidden;
}
.orderDetailsScreenItemImage {
    width: 100%;
    min-width: 100px;
    cursor: pointer;
    transition: 0.1s ease-out;
}
.orderDetailsScreenItemImage:hover {
    transform: scale(1.05);
}

.orderDetailsScreenItemDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 300;
}

.orderDetailsScreenItemDetail {
    align-self: flex-start;
    text-align: right;
}

.orderDetailsScreenItemDetailsName {
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.1;
    align-self: flex-end;
    cursor: pointer;
    transition: 0.1s ease;
}

.orderDetailsScreenItemDetailsArtist,
.orderDetailsScreenItemDetailsQty {
    font-size: 1em;
    align-self: flex-start;
}
.orderDetailsScreenItemDetailsArtist {
    cursor: pointer;
    z-index: 99;
    transition: 0.1s ease;
}
.orderDetailsScreenItemDetailsArtist:hover,
.orderDetailsScreenItemDetailsName:hover {
    color: hsl(220, 50%, 35%);
}

.orderDetailsScreenItemDetailsDevice {
    font-size: 1em;
    font-weight: 500;
}

.orderDetailsScreenItemDetailsPrice {
    margin-top: 2px;
    align-self: flex-start;
    font-size: 1.2em;
    line-height: 1;
    font-weight: 400;
}

.orderDetailsScreenTotal {
    width: 30%;
    height: 100%;
    padding: 2vh 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px dashed #d4d4d4;
    border-radius: 5px;
    text-align: center;
    gap: 1vh;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.orderDetailsScreenTotalWrapper {
    width: 100%;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #dddddd;
}

.orderDetailsScreenTotalWrapper p {
    font-family: 'Poppins', sans-serif;
    font-size: calc(9px + 0.3vmax);
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2;
}

.orderDetailsScreenTotalWrapper span {
    font-family: 'Poppins', sans-serif;
    font-size: calc(9px + 0.3vmax);
    font-weight: 400;
    line-height: 1.5;
}

.orderDetailsScreenCheckoutBtn {
    width: 100%;
    margin-top: 2vh;
    padding: 15px 25px;
    font-family: 'Open Sans', 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(230, 230, 230);
    border: none;
    border-radius: 5px;
    user-select: none;
    transition: 0.1s ease;
}

.orderDetailsScreenCheckoutBtnActive {
    background: linear-gradient(var(--button-click-color), var(--button-color));
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.orderDetailsScreenCheckoutBtnActive:hover {
    filter: brightness(1.3);
}
.orderDetailsScreenCheckoutBtnActive:hover:active {
    box-shadow: 0px 0px 5px var(--button-color);
}

.orderDetailsScreenCheckoutBtnDisabled {
    background: var(--button-disabled-color);
    box-shadow: inset 0px 0px 2px rgb(230, 230, 230);
    color: white;
    border: none;
    cursor: default;
}
.orderDetailsScreenCheckoutBtn p {
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(207, 207, 207);
}

@media (max-width: 820px) {
    .orderDetailsScreenInnerContainer {
        flex-direction: column;
    }
    .orderDetailsScreenInnerContainerLeft {
        width: 100%;
    }
    .orderDetailsScreenTotal {
        width: 100%;
    }
}
