.HeaderContainer {
    width: 100%;
    max-height: var(--navbar-header-height);
    padding: 1vh 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(211, 32%, 86%);
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: calc(8px + 0.5vmin);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    white-space: nowrap;
    color: #242424;
}

.collapse {
    height: 0;
    padding: 0;
    font-size: 0rem;
    opacity: 0;
    transition: 0.5s ease-in-out, opacity 0.2s ease-in-out;
}

.HeaderCloseContainer {
    width: 1.2rem;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 2vw;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    color: var(--main-color);
    transition: 0.2 s ease;
}
.HeaderCloseContainer:hover {
    background: #242424;
    color: #ffffff;
}

.HeaderCloseIcon {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 900;
    font-size: 1em;
}
